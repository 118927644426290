// ключи localStorage
const LOCAL_STORAGE_KEY = {
  landingPlanId: 'landing_plan_id',
  gameId: 'game_id',
  gamesCatalogPerPage: 'games_catalog_per_page',
  isPurchased: 'is_purchased',
  launcher: 'launcher',
  cloudSessionResult: 'cloud_session_result',
  networkInterfaceParams: 'network_interface_params',
  planCalculatorExternalReturnUrl: 'plan_calculator_external_return_url',
  publicExperiments: 'public_experiments',
  offerAfterSessionData: '__app_offer_after_session_data',
  isMonth: 'is_month',
  isHintShown: '28058_is_hint_shown',
  isMacOnboardingHintsNeedShow: 'is_mac_onboarding_hints_need_show',
  optionEndDaysLeft: 'option_end_days_left',
  vkUserId: 'vk_user_id',
  isHourPlanEndBannerClosed: 'is_hour_plan_end_banner_closed',
  serverType: 'server_type',
  serverData: 'server_data',
  isRemotePlayHintViewed: 'is_remote_play_hint_viewed',
  currentRemotePlayDevice: 'current_remote_play_device',
  isRemotePlayOnboardingPassed: 'is_remote_play_onboarding_passed',
  savedQuizValue: 'saved_quiz_value',
  quizStepNumber: 'quiz_step_number',
  onlyStartQuestHint: 'only_start_quest_hint',
  omicronConfig: 'omicron_config',
} as const;

// ключи sessionStorage
const SESSION_STORAGE_KEY = {
  orderId: 'order_id',
  orderPrice: 'order_price',
  orderProduct: 'order_product',
  orderProductName: 'order_product_name',
  fromGc: 'from_gc',
  vkConnectSenderTask: 'vk_connect_sender_task',
  vkConnectSenderWarning: 'vk_connect_sender_warning',
  vkConnectReturnUrl: 'vk_connect_return_url',
  vkConnectNotify: 'vk_connect_notify',
  newsletterConsentHighlight: 'newsletter_consent_highlight',
} as const;

export const STORAGE_KEY = {
  ...LOCAL_STORAGE_KEY,
  ...SESSION_STORAGE_KEY,
} as const;

// ключи localStorage, для очистки
export const STORAGE_KEYS_TO_REMOVE = [
  'cg_current_game_queue',
  '__app_last_time_offer',
] as const;
